import { render, staticRenderFns } from "./TotalStorageOverview.vue?vue&type=template&id=95af0cba&scoped=true"
import script from "./TotalStorageOverview.vue?vue&type=script&lang=js"
export * from "./TotalStorageOverview.vue?vue&type=script&lang=js"
import style0 from "./TotalStorageOverview.vue?vue&type=style&index=0&id=95af0cba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95af0cba",
  null
  
)

export default component.exports