<template>
    <Card class="card px-2 py-4 text-darkPurple flex justify-start items-center gap-2">
        <div>
            <icon  v-if="iconName" :icon-name="iconName" class="width" size="xs"/>
            <CircularProgressBar :percentage="percentage" v-else />
        </div>   
        <div>
            <p class="text-jet text-sm font-semibold">{{ title }}</p>
            <p class="font-black text-romanSilver size">{{ gbUseage }} of {{ totalGB }}</p>
        </div>
    </Card>
</template>
  
<script>

export default {
    name: "TotalStorageOverview",
    components: {
        Card: () => import('@/components/Card'),
        CircularProgressBar: () => import('./CircularProgressBar'),
    },
    props: {
        title: String,
        iconName: String, 
        percentage: Number,
        totalGB: String || Number,
        gbUseage: String || Number,
    },
    data() {
        return {
        };
    },

    methods: {
    },
};
</script>

<style scoped>
.card{
    width: calc(100% / 3) !important;
    height: 68px !important;
    border: 1px solid #C2C7D6 !important;
    border-radius: 8px !important;
}
.width{
    width: 36px !important;
    height: 36px !important;
}
.size{
    font-size: 10px;
}
</style>